@import '_variables.scss';


.footer2 {
    display: flex;
    flex-direction: row;
    background-color: $main-color;
    // border: $test;
    width: 100%;
    bottom: 0;
    height: $footer-height;
    margin: 0%;
    color: white;
    position: absolute;

    .side-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0%;
        padding-right: 6%;
        background-color: $dark-main-color;
        margin-left: 4%;

        h2 {
            letter-spacing: 1px;
            font-weight: 400;
            margin-top: 5%;
            margin-bottom: 1%;
            // margin-left: 0%;
            color: white;
        }


        li {
            display: flex;
            // border: 2px solid white;
            margin-top: 10px;
        }

        ul {
            // border: 2px solid white;
            display: flex;
            // align-items: center;
            flex-direction: column;
            margin: 0;
            padding-left: 1rem;

            img {
                width: 25px;
                height: 20px;
                // border: 2px solid white;
                // align-items: center;
                margin: 0;
                margin-right: 10px;
            }

            p {
                display: flex;
                // align-items: center;
                margin: 0;
                // border: 2px solid white;
                white-space: nowrap;
            }

        }



    }


    a {
        color: white;
        font-size: x-large;
        letter-spacing: 0.75px;
        // border: $test;
    }

}

.submit {
    // border: $test;
    display: flex;
    align-items: flex-end;
    position: fixed;
    margin-right: 2vw;
    // margin-bottom: 0vw;
    bottom: 0;
    right: 0;
    z-index: 1;

    .order {
        width: 11rem;
        height: 3rem;
        background-color: $dark-accent;
        box-shadow: 5px 7px rgb(0 0 0 / 16%), 1px 2px rgb(0 0 0 / 23%);

    }
}



@media only screen and (max-width: $sm-screen) {
.footer2 {
    background-color:$dark-main-color;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .side-section {
            margin-left: 0;
            margin-right: 0;
        }
    }

}